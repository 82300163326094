<template>
  <div class="Index">
    <!-- 头部 -->
    <header class="t-header dflex-jb-ac">
      <router-link to="/index">
        <!-- <div class="logo" title="返回首页"></div> -->
      </router-link>

      <div class="hd-right">
        <div @click="logout" title="退出登陆" class="icon mr30">
          <svg
            t="1689144777110"
            class="icon_exit"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3128"
            width="16"
            height="16"
          >
            <path
              d="M511.983593 1024C448.652538 1024 387.150464 1011.535486 329.297798 986.831913 273.379691 963.081843 223.233865 929.044243 180.138615 885.661131 137.043366 842.283763 103.221434 791.737862 79.553073 735.492463 55.082927 677.261069 42.666667 615.389406 42.666667 551.610731 42.666667 475.288561 61.25753 399.529306 96.389139 332.401869 130.356591 267.550498 179.699202 210.610071 239.122626 167.742483 255.629133 155.83514 278.568467 159.693683 290.365555 176.275219 302.158364 192.851012 298.39625 215.900309 281.889743 227.741598 231.671157 264.062443 189.998293 312.072361 161.363712 366.808521 131.309602 424.192672 116.078534 486.355842 116.078534 551.610731 116.078534 658.07175 157.273466 758.171273 232.073476 833.42649 306.833542 908.71043 406.24922 950.180785 511.983593 950.180785 617.679447 950.180785 717.127938 908.71043 791.927949 833.42649 866.726534 758.171273 907.888653 658.07175 907.888653 551.610731 907.888653 486.429079 892.690398 424.153899 862.597766 366.808521 833.963187 312.106825 792.256083 264.062443 742.038925 227.814833 725.565231 215.972111 721.730357 192.924247 733.561685 176.348454 745.398718 159.766918 768.298108 155.868169 784.771802 167.815721 844.229463 210.683309 893.643409 267.623733 927.572341 332.473668 962.74247 399.601105 981.333333 475.367541 981.333333 551.683968 981.333333 615.462643 968.917073 677.334306 944.40698 735.604471 920.778566 791.849871 886.923821 842.39577 843.827145 885.773139 800.731895 929.150509 750.58179 963.155081 694.669389 987.017156 636.778204 1011.56995 575.31465 1024 511.983593 1024L511.983593 1024ZM523.011708 511.391134C502.743089 511.391134 486.309342 494.921606 486.309342 474.481527L486.309342 36.944072C486.309342 16.581537 502.743089 0 523.011708 0 543.280329 0 559.754022 16.581537 559.754022 36.944072L559.754022 474.481527C559.754022 494.921606 543.280329 511.391134 523.011708 511.391134L523.011708 511.391134Z"
              fill="#718EBF"
              p-id="3129"
            ></path>
          </svg>
        </div>
        <!-- <div title="消息" class="cupo icon mr30">
          <img src="@/assets/image/icon/msg00.png" alt="" />
        </div> -->
        <div title="设置" class="people" @click="toSetting">
          <span>{{ teaching_userInfo.user_name.charAt(0) }}</span>
        </div>
      </div>
    </header>
    <div class="t-contain">
      <div class="t-aside">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
          :default-openeds="openeds"
          @open="handleOpen"
          @close="handleClose"
          text-color="#3d3d3d"
          active-text-color="#0091FF"
          router
        >
          <el-menu-item index="/index">
            <img src="@/assets/image/icon/home.svg" alt="" />
            <span slot="title">首页</span>
          </el-menu-item>
          <template v-if="teaching_userInfo.id_admin == 1">
            <el-submenu index="1">
              <template slot="title">
                <img src="@/assets/image/icon/menu1.svg" alt="" />
                <span>系统管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/system/role">角色管理</el-menu-item>
                <el-menu-item index="/system/authority">权限管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <img src="@/assets/image/icon/menu1.svg" alt="" />
                <span>教师管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/teacher/list">教师列表</el-menu-item>
                <!-- <el-menu-item index="/teacher/add">新增教师</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
          </template>

          <template v-else>
            <el-submenu index="3">
              <template slot="title">
                <img src="@/assets/image/icon/menu2.svg" alt="" />
                <span>班级管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/class/list">班级列表</el-menu-item>
                <!-- <el-menu-item index="/class/add">新增班级</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <img src="@/assets/image/icon/menu3.svg" alt="" />
                <span>学生管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/student/list"> 学生列表</el-menu-item>
                <!-- <el-menu-item index="/student/add">新增学生</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <img src="@/assets/image/icon/menu4.svg" alt="" />
                <span>交流中心</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/posts">帖子管理</el-menu-item>
                <el-menu-item index="/reply">回复管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">
                <img src="@/assets/image/icon/menu5.svg" alt="" />
                <span>教学管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/teaching/monitor/basicData">
                  教学监控</el-menu-item
                >
                <el-menu-item index="/teaching/course">课程管理</el-menu-item>
                <el-menu-item index="/teaching/practicalTrain"
                  >实训任务管理</el-menu-item
                >
                <el-menu-item index="/teaching/courseMaterials"
                  >课程资料管理</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">
                <img src="@/assets/image/icon/menu6.svg" alt="" />
                <span>课程题库</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/grade/examinationPaper_list"
                  >试卷管理</el-menu-item
                >
                <el-menu-item index="/grade/question_list">
                  题库管理</el-menu-item
                >
                <el-menu-item index="/grade/achievement">
                  成绩管理</el-menu-item
                >
                <!-- <el-menu-item index="/grade/test_list"> 考试管理</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </div>
      <main class="t-main">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      openeds: ["1", "2", "3", "4", "5", "6", "7"], //默认展开导航栏
    };
  },
  computed: {
    ...mapState(["teaching_userInfo"]),
    defaultActive() {
      let pathName = this.$route.meta.name;
      if (pathName == "system") {
        let path = "/system/role";
        return path;
      } else if (pathName == "teacher") {
        let path = "/teacher/list";
        return path;
      } else if (pathName == "class") {
        let path = "/class/list";
        return path;
      } else if (pathName == "student") {
        let path = "/student/list";
        return path;
      } else if (pathName == "monitor") {
        let path = "/teaching/monitor/basicData";
        return path;
      } else if (pathName == "courseGL") {
        let path = "/teaching/course";
        return path;
      } else if (pathName == "practicalTrain") {
        let path = "/teaching/practicalTrain";
        return path;
      } else if (pathName == "courseMaterials") {
        let path = "/teaching/courseMaterials";
        return path;
      } else if (pathName == "examinationPaper_list") {
        let path = "/grade/examinationPaper_list";
        return path;
      } else if (pathName == "question_list") {
        let path = "/grade/question_list";
        return path;
      } else if (pathName == "achievement") {
        let path = "/grade/achievement";
        return path;
      } else {
        return this.$route.path;
      }
    },
  },
  methods: {
    openMessageBox(text, type) {
      this.$alert(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: type,
      });
    },
    logout() {
      this.$confirm("确定要退出登陆吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 清空用户数据
          localStorage.removeItem("user_uuid");
          localStorage.removeItem("teaching_userInfo");
          setTimeout(() => {
            this.$router.push("/login");
          }, 200);
        })
        .catch(() => {});
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    // 去设置
    toSetting() {
      this.$router.push("/setting");
    },
  },
};
</script>

<style lang="scss" scoped>
.Index {
  display: flex;
  flex-direction: column;
  height: 100vh;
  // height: 100vh;
}
.t-header {
  // min-width: 1143px;
  width: 100%;
  height: 70px;

  // height: 70px;
  // line-height: 40px;
  position: fixed;
  top: 0;

  padding: 15px 40px;
  padding-left: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;

  .hd-left {
    .logo {
      margin-right: 64px;
    }
  }
  .hd-right {
    display: flex;
    align-items: center;
    .icon {
      width: 30px;
      height: 30px;
      background: #f5f7fa;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        .icon_exit {
          path {
            fill: #40a9ff;
          }
        }
      }
      .icon_exit {
        width: 19px;
        height: 19px;
      }
    }
    .people {
      width: 40px;
      height: 40px;
      background: #0091ff;
      color: #ffffff;
      font-size: 16px;
      font-family: PingFang SC-粗体, PingFang SC;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
    .people:hover {
      opacity: 0.8;
    }
  }
}
.pageName {
  margin-left: 30px;
  font-size: 24px;
  font-family: PingFang SC-粗体, PingFang SC;
  font-weight: 600;
}

.t-contain {
  // height: 100%;
  padding-top: 70px;
  flex: 1;
  display: flex;
  .t-aside {
    width: 250px;
    // height: 100vh;
    background: #fff;
    // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    user-select: none;
    height: calc(100vh - 70px);
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  }
  .t-aside::-webkit-scrollbar {
    display: none;
  }

  // .t-aside::-webkit-scrollbar-thumb {
  //   border-radius: 4px;
  //   background-color: transparent;
  // }

  // .t-aside:hover::-webkit-scrollbar-thumb {
  //   background-color: #a0a0a0;
  // }

  .t-main {
    background: #f5f7fa;
    flex: 1;
    height: calc(100vh - 70px);
    // min-width: 1000px;
    overflow: auto;
  }
}

// ******************************

.el-menu {
  border-right: none;
}
::v-deep .el-menu > .el-menu-item::before {
  content: "";
  width: 6px !important;
  height: 50px;
  display: inline-block;
  background: #fff;
  border-radius: 0px 2px 2px 0px;
}
::v-deep .el-menu > .el-menu-item {
  &.is-active::before {
    content: "";
    width: 6px !important;
    height: 40px;
    color: #0091ff;
    display: inline-block;
    background: #0091ff !important;
    border-radius: 0px 2px 2px 0px;
  }
}

.el-dropdown-menu__item,
.el-menu-item {
  font-size: 14px;
  padding: 0 !important;
  cursor: pointer;
}

.el-menu-item > img {
  margin-right: 20px !important;
  margin-left: 14px !important;
  width: 20px;
  height: 20px;
}
.el-menu-item > span {
  font-size: 16px;
  font-weight: 600;
}
// .el-menu-item > span {
//   font-size: 18px;
//   color: #3d3d3d;
//   font-weight: 600;
// }
.el-menu-item.is-active {
  // color: #409eff !important;
  background: #f6f8fa !important;
}
// .el-menu-item:hover {
//   background: #f6f8fa !important;
//   // background: none !important;
// }
.el-submenu__title > img {
  margin-right: 20px !important;
  width: 20px;
  height: 20px;
}
.el-submenu__title > span {
  font-size: 16px;
  font-weight: 600;
}

// ::v-deep .el-submenu>ul{
//   margin-right: 20px !important;
// }
::v-deep .el-menu-item-group > ul > li {
  // padding-left: 65px !important;
  // color: #3d3d3d;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  display: flex;
  align-items: center;
}
::v-deep .el-menu-item-group__title {
  padding: 0 !important;
}

::v-deep .el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 50px !important;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 12px;
}
// 2.更改menu title的高度
::v-deep .el-menu > .el-menu-item {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
}
::v-deep .el-submenu .el-submenu__title {
  height: 40px !important;
  line-height: 40px;
}

.el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
}

// 3.选中submenu出现蓝色左边框
::v-deep .el-submenu .el-menu-item::before {
  content: "";
  width: 6px !important;
  height: 40px;
  display: inline-block;
  background: #fff;
  border-radius: 0px 2px 2px 0px;
  margin-right: 60px;
}
::v-deep .el-submenu .el-menu-item:hover {
  &::before {
    background: #ecf5ff;
  }
}
::v-deep .el-submenu .el-menu-item {
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  &.is-active::before {
    content: "";
    width: 6px !important;
    height: 40px;
    color: #0091ff;
    display: inline-block;
    background: #0091ff;
    border-radius: 0px 2px 2px 0px;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
}
</style>
<style>
/* 1.更改导航栏的向下图标 */
.el-submenu__title i:before {
  content: "\e790" !important;
  font-size: 16px;
  color: #718ebf;
  /*padding-right: 50px !important; */
}
</style>
