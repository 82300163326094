import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // strict: true,
  state: {
    user_uuid: JSON.parse(localStorage.getItem("user_uuid")), //全局保存用户的user_uuid
    uploadUrl: 'http://jdzmapi.puteedu.com/teachapi/uploadPicApi',
    baseUrl: 'http://jdzmapi.puteedu.com',
    app_source_from: 'amazon_smt_xiapi',
    roleList: '',
    authList: '',
    teacherList: '',
    classList: '',
    studentList: '',
    courseList: '',
    practicalTrainList: '',
    questionList: '',
    examPaperList: '',
    examList: '',
    cmList: '',
    teaching_userInfo: JSON.parse(localStorage.getItem("teaching_userInfo")),
  },
  getters: {
  },
  mutations: {
    getUserInfo(state, obj) {
      state.teaching_userInfo = obj
    },
    getUserUuid(state, uuid) {
      state.user_uuid = uuid
    }
  },
  actions: {
  },
  modules: {
  }
})
