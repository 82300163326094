import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.scss'
import '@/assets/styles/element.css'
import 'default-passive-events'
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 全局引入axios，优雅引入
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = 'http://jdzapi.puteedu.com'
// axios.defaults.baseURL = 'http://www.school_api.com' //本地


//设置请求拦截器
// axios.interceptors.request.use(
//   (config) => {
//     config.data.app_source_from ='amazon_smt_xiapi'
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );


Vue.use(VueAxios,axios);

Vue.use(ElementUI);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
