import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

//解决相同路由跳转报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "ssssssss",
    component: Index,
    // meta: { isAuth: true, },
    redirect: "/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/homeView.vue"),
        meta: { isAuth: true },
      },
      // 系统管理
      {
        path: "system",
        component: () => import("@/views/system/role.vue"),
        meta: { isAuth: true },
        redirect: "system/role",
      },
      {
        path: "system/role",
        component: () => import("@/views/system/role.vue"),
        meta: { isAuth: true, name: "system" },
      },
      {
        path: "system/authority",
        component: () => import("@/views/system/authority.vue"),
        meta: { isAuth: true, name: "system" },
      },
      {
        path: "system/add",
        component: () => import("@/views/system/add.vue"),
        meta: { isAuth: true, name: "system" },
      },
      {
        path: "system/add1",
        component: () => import("@/views/system/add1.vue"),
        meta: { isAuth: true, name: "system" },
      },
      // 教师管理
      {
        path: "teacher",
        component: () => import("@/views/teacher/list.vue"),
        meta: { isAuth: true, name: "teacher" },
        redirect: "teacher/list",
      },
      {
        path: "teacher/list",
        component: () => import("@/views/teacher/list.vue"),
        meta: { isAuth: true, name: "teacher" },
      },
      {
        path: "teacher/add",
        component: () => import("@/views/teacher/add.vue"),
        meta: { isAuth: true, name: "teacher" },
      },
      // 班级管理
      {
        path: "class",
        component: () => import("@/views/class/list.vue"),
        meta: { isAuth: true, name: "class" },
        redirect: "class/list",
      },
      {
        path: "class/list",
        component: () => import("@/views/class/list.vue"),
        meta: { isAuth: true, name: "class" },
      },
      {
        path: "class/add",
        component: () => import("@/views/class/add.vue"),
        meta: { isAuth: true, name: "class" },
      },
      // 学生管理
      {
        path: "student",

        component: () => import("@/views/student/list.vue"),
        meta: { isAuth: true },
        redirect: "student/list",
      },
      {
        path: "student/list",

        component: () => import("@/views/student/list.vue"),
        meta: { isAuth: true, name: "student" },
      },
      {
        path: "student/add",

        component: () => import("@/views/student/add.vue"),
        meta: { isAuth: true, name: "student" },
      },
      {
        path: "student/learning_record",

        component: () => import("@/views/student/learning_record.vue"),
        meta: { isAuth: true, name: "student" },
      },
      // 交流中心
      {
        path: "/posts",
        component: () => import("@/views/exchange_centre/posts.vue"),
        meta: { isAuth: true },
      },
      {
        path: "/reply",
        component: () => import("@/views/exchange_centre/reply.vue"),
        meta: { isAuth: true },
      },
      // 教学管理
      {
        path: "teaching",
        component: () => import("@/views/teaching/monitor.vue"),
        meta: { isAuth: true, name: "monitor" },
        redirect: "teaching/monitor",
      },
      {
        path: "teaching/monitor",
        component: () => import("@/views/teaching/monitor.vue"),
        meta: { isAuth: true },
        redirect: "teaching/monitor/index",
        children: [
          {
            path: "basicData",
            component: () => import("@/views/teaching/basicData_monitor.vue"),
            meta: { isAuth: true, name: "monitor" },
          },
          {
            path: "index",
            component: () => import("@/views/teaching/data_monitor.vue"),
            meta: { isAuth: true, name: "monitor" },
          },
          {
            path: "study",
            component: () => import("@/views/teaching/study_monitor.vue"),
            meta: { isAuth: true, name: "monitor" },
          },
          {
            path: "progress",
            component: () => import("@/views/teaching/study_progress.vue"),
            meta: { isAuth: true, name: "monitor" },
          },
        ],
      },
      {
        path: "/teaching/score_details",
        component: () => import("@/views/teaching/score_details.vue"),
        meta: { isAuth: true, name: "monitor" },
      },
      {
        path: "teaching/practicalDetails",

        component: () => import("@/views/teaching/practicalTrainData.vue"),
        meta: { isAuth: true, name: "monitor" },
      },
      {
        path: "teaching/course",

        component: () => import("@/views/teaching/course.vue"),
        meta: { isAuth: true, name: "courseGL" },
      },
      {
        path: "teaching/courseEdit",

        component: () => import("@/views/teaching/courseEdit.vue"),
        meta: { isAuth: true, name: "courseGL" },
      },
      {
        path: "teaching/practicalTrain",

        component: () => import("@/views/teaching/practicalTrain.vue"),
        meta: { isAuth: true, name: "practicalTrain" },
      },
      {
        path: "teaching/practicalTrainReport",

        component: () => import("@/views/teaching/practicalTrainReport.vue"),
        meta: { isAuth: true, name: "practicalTrain" },
      },
      {
        path: "teaching/practicalTrainEdit",

        component: () => import("@/views/teaching/practicalTrainEdit.vue"),
        meta: { isAuth: true, name: "practicalTrain" },
      },
      {
        path: "teaching/courseMaterials",

        component: () => import("@/views/teaching/courseMaterials.vue"),
        meta: { isAuth: true, name: "courseMaterials" },
      },
      {
        path: "teaching/courseMaterialEdit",

        component: () => import("@/views/teaching/courseMaterialsEdit.vue"),
        meta: { isAuth: true, name: "courseMaterials" },
      },
      // 考试考证
      {
        path: "/grade",
        component: () => import("@/views/grade/question_list.vue"),
        meta: { isAuth: true },
        redirect: "grade/question_list",
      },
      {
        path: "grade/examinationPaper_list",
        component: () => import("@/views/grade/examinationPaper_list.vue"),
        meta: { isAuth: true, name: "examinationPaper_list" },
      },
      {
        path: "grade/examinationPaper_add",
        component: () => import("@/views/grade/examinationPaper_add.vue"),
        meta: { isAuth: true, name: "examinationPaper_list" },
      },
      {
        path: "grade/achievement",
        component: () => import("@/views/grade/achievement.vue"),
        meta: { isAuth: true, name: "achievement" },
      },
      {
        path: "grade/achievement_detail",
        component: () => import("@/views/grade/achievement_detail.vue"),
        meta: { isAuth: true, name: "achievement" },
      },
      {
        path: "grade/question_list",
        component: () => import("@/views/grade/question_list.vue"),
        meta: { isAuth: true, name: "question_list" },
      },
      {
        path: "grade/question_add",
        component: () => import("@/views/grade/question_add.vue"),
        meta: { isAuth: true, name: "question_list" },
      },

      {
        path: "grade/test_list",
        component: () => import("@/views/grade/test_list.vue"),
        meta: { isAuth: true },
      },
      {
        path: "grade/test_add",
        component: () => import("@/views/grade/test_add.vue"),
        meta: { isAuth: true },
      },
      // 管理员设置
      {
        path: "setting",
        component: () => import("@/views/setting.vue"),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
    meta: { isAuth: false },
  },
  {
    path: "/test",
    name: "routeName",
    component: () => import("@/components/pieChart.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    // 控制跳到下一页面回到顶端
    if (savedPosition) {
      // 浏览器前进后退触发记录位置
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});

//全局前置路由，配合浏览器localStorage进行鉴权操作
router.beforeEach((to, from, next) => {
  //首先，我们先看一看to和from参数，next就是执行的意思，不写页面是不会跳转的
  if (to.meta.isAuth) {
    //判断是否需要鉴权
    if (JSON.parse(localStorage.getItem("teaching_userInfo"))) {
      next();
    } else if (!JSON.parse(localStorage.getItem("teaching_userInfo"))) {
      router.push("/login");
    }
  } else {
    next();
  }
});

export default router;
